<template>
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="point-detail">
      <path
        id="Stroke 207"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.28571 0.5H17.7143C18.9764 0.5 20 1.35183 20 2.40406V15.2528C20 16.3051 18.9764 17.1569 17.7143 17.1569H2.28571C1.02357 17.1569 0 16.3051 0 15.2528V2.40406C0 1.35183 1.02357 0.5 2.28571 0.5ZM17.7143 2.16666H2.28571C2.12754 2.16666 2 2.2728 2 2.40405V15.2528C2 15.3841 2.12754 15.4902 2.28571 15.4902H17.7143C17.8725 15.4902 18 15.3841 18 15.2528V2.40405C18 2.2728 17.8725 2.16666 17.7143 2.16666Z"
        fill="#E5472D"
      />
      <path
        id="Stroke 208"
        d="M19 4.7832C19.5523 4.7832 20 5.1563 20 5.61654C20 6.0439 19.614 6.39613 19.1166 6.44426L19 6.44987H1C0.447715 6.44987 0 6.07677 0 5.61654C0 5.18917 0.38604 4.83695 0.883379 4.78881L1 4.7832H19Z"
        fill="#E5472D"
      />
      <path
        id="Stroke 209"
        d="M7.42773 4.7832C7.94057 4.7832 8.36324 5.1049 8.42101 5.51935L8.42773 5.61654V16.3238C8.42773 16.7841 7.98002 17.1572 7.42773 17.1572C6.9149 17.1572 6.49223 16.8355 6.43446 16.421L6.42773 16.3238V5.61654C6.42773 5.1563 6.87545 4.7832 7.42773 4.7832Z"
        fill="#E5472D"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
